import React from "react";
import { Link } from "react-router-dom";
import {
  GoogleMap,
  LoadScript,
  Marker,
  Polyline,
} from "@react-google-maps/api";
import { GoogleMapsOverlay } from "@deck.gl/google-maps";
import { Button, Slider, Backdrop } from "@mui/material";
import { ArrowBack, InfoOutlined, CloseOutlined } from "@mui/icons-material";
import { ScatterplotLayer } from "deck.gl";
import BounceLoader from "react-spinners/BounceLoader";
import {
  theme,
  mobileTheme,
  letterStyles,
  navbar,
  mobileLetterStyles,
} from "./diss_styles";
import axios from "axios";
import darkmode from "./assets/darkmode";
import InfoComponent from "./InfoComponent";
import { color } from "framer-motion";

function Diss() {
  const [map, setMap] = React.useState();
  const [clicks, setClicks] = React.useState([]);
  const [center, setCenter] = React.useState({
    lat: 55.865,
    lng: -4.25,
  });
  const [loading, setLoading] = React.useState(false);
  const [path, setPath] = React.useState([]);
  const [dijkstraProps, setDijksProps] = React.useState([]);
  const [astarProps, setAstarProps] = React.useState([]);
  const [overlay, setOverlay] = React.useState(new GoogleMapsOverlay());
  const [loopLength, setLoopLength] = React.useState(500);
  const mapButton = React.useRef(null);
  const logo = React.useRef(null);
  const homeBtn = React.useRef(null);
  const infoBtn = React.useRef(null);
  const slider = React.useRef(null);
  const [valid, setValid] = React.useState([false, false]);
  const [scatterAnimateID, setScatterID] = React.useState();

  const [algIndex, setIndex] = React.useState(0);
  const [infoToggle, setInfoToggle] = React.useState(true);
  const [windowSize, setWindowSize] = React.useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const nsBounds = [55.89, 55.85];
  const ewBounds = [-4.3, -4.2];

  React.useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  let currentTime = 0;

  const onClick = (e) => {
    console.log(e.latLng.lat());
    if (
      e.latLng.lat() > nsBounds[0] ||
      e.latLng.lat() < nsBounds[1] ||
      e.latLng.lng() > ewBounds[1] ||
      e.latLng.lng() < ewBounds[0]
    ) {
      return;
    }

    let newClicks = clicks;
    if (clicks.length === 2) {
      newClicks.shift();
    }
    newClicks.push(e.latLng);
    setClicks(newClicks);
    setCenter(e.latLng);
    setValid([false, false]);
  };

  const dijkstra = async () => {
    setIndex(1);
    clearAnimatedLayers();
    if (!valid[0]) {
      setLoading(true);
      const result = await axios.post("/api/shortestroute", {
        markers: clicks,
        astar: false,
      }).catch((err) => { console.log(err); return; });
      if (!result) {
        console.log("exited");
        setLoading(false);
        return;
      }
      let algTime =
        result.data.scatterData[result.data.scatterData.length - 1][3];
      setDijksProps([
        result.data.scatterData,
        result.data.traceback,
        result.data.tripTime,
        algTime,
      ]);
      setLoopLength(algTime);
      setLoading(false);
      animatedScatter(result.data.scatterData);
      setPath(result.data.traceback);
      setValid([true, valid[1]]);
    } else {
      setLoopLength(dijkstraProps[3]);
      animatedScatter(dijkstraProps[0], dijkstraProps[4]);
      setPath(dijkstraProps[1]);
    }
  };

  const astar = async () => {
    console.log(clicks);
    clearAnimatedLayers();
    setIndex(2);
    if (!valid[1]) {
      setLoading(true);
      const result = await axios.post("/api/shortestroute", {
        markers: clicks,
        astar: true,
      }).catch((err) => { console.log(err); return; });
      if (!result) {
        console.log("exited");
        setLoading(false);
        return;
      }

      let algTime =
        result.data.scatterData[result.data.scatterData.length - 1][3];
      setAstarProps([
        result.data.scatterData,
        result.data.traceback,
        result.data.tripTime,
        algTime,
      ]);
      setLoading(false);
      animatedScatter(result.data.scatterData, algTime);
      setPath(result.data.traceback);
      setLoopLength(algTime);
      setValid([valid[0], true]);
    } else {
      setLoopLength(astarProps[3]);
      animatedScatter(astarProps[0], astarProps[4]);
      setPath(astarProps[1]);
    }
  };

  function renderScatter(data) {
    let d = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i][3] > currentTime) {
        break;
      }
      d.push(data[i]);
    }

    const scatterplotLayer = new ScatterplotLayer({
      id: "scatter-plot",
      data: d,
      radiusScale: 7,
      radiusMinPixels: 0.25,
      getPosition: (d) => [d[0], d[1], 0],
      // getFillColor: (d=> [255,40+(d[2]*0.6),20+(d[2]*0.5),255])
      getFillColor: (d) => [255, d[2], 100, 255],
    });

    overlay.setProps({
      layers: [scatterplotLayer],
    });
  }

  const animatedScatter = (data) => {
    clearAnimatedLayers();
    const animateScatter = () => {
      currentTime = currentTime + 1;
      renderScatter(data);
      setScatterID(window.requestAnimationFrame(animateScatter));
    };
    setScatterID(window.requestAnimationFrame(animateScatter));
  };

  function clearAnimatedLayers() {
    window.cancelAnimationFrame(scatterAnimateID);
    overlay.setProps({ layers: [] });
  }

  function timeSlider(event) {
    console.log(scatterAnimateID);
    window.cancelAnimationFrame(scatterAnimateID);
    currentTime = event.target.value;
    if (algIndex === 1) {
      renderScatter(dijkstraProps[0]);
    } else if (algIndex === 2) {
      renderScatter(astarProps[0]);
    }
  }

  const clear = async () => {
    clearAnimatedLayers();
    setDijksProps([]);
    setAstarProps([]);
    setPath([]);
    setClicks([]);
    console.log(map);
  };

  function mapButtons() {
    return (
      <div style={navbar} ref={mapButton}>
        <div style={{ maxWidth: "500px", width: "90%", margin: "auto" }}>
          <Slider
            ref={slider}
            min={0}
            max={loopLength}
            style={theme}
            onChange={(event) => timeSlider(event)}
            aria-label="Default"
          />
        </div>
        <div
          style={{ ...navbar, maxWidth: "500px", width: "90%", margin: "auto" }}
        >
          <Button
            style={windowSize[0] < 600 ? mobileTheme : theme}
            onClick={dijkstra}
          >
            Dijkstra
          </Button>
          <Button
            style={windowSize[0] < 600 ? mobileTheme : theme}
            onClick={astar}
          >
            A-Star
          </Button>
          <Button
            style={windowSize[0] < 600 ? mobileTheme : theme}
            onClick={clear}
          >
            Clear
          </Button>
        </div>
      </div>
    );
  }

  function logoRender() {
    return (
      <div ref={logo}>
        <div>
          {["R", "O", "U", "T", "E"].map((letter, index) => {
            return (
              <p
                key={index}
                style={
                  windowSize[0] < 600 || windowSize[1] < 600
                    ? mobileLetterStyles
                    : letterStyles
                }
              >
                {letter}
              </p>
            );
          })}
        </div>
        <div style={{ margin: "5%" }}>
          {["M", "A", "P", "P", "E", "R"].map((letter, index) => {
            return (
              <p
                key={index}
                style={
                  windowSize[0] < 600 || windowSize[1] < 600
                    ? mobileLetterStyles
                    : letterStyles
                }
              >
                {letter}
              </p>
            );
          })}
        </div>
      </div>
    );
  }

  function homeButton() {
    return (
      <Link
        to={"/"}
        ref={homeBtn}
        style={{
          ...theme,
          borderRadius: "3px",
          width: "40px",
          height: "40px",
          margin: "10px 5px",
        }}
      >
        {" "}
        <ArrowBack style={{ margin: "20%" }}></ArrowBack>
      </Link>
    );
  }

  function infoButton() {
    return (
      <div
        ref={infoBtn}
        onClick={() => setInfoToggle(true)}
        style={{
          ...theme,
          cursor: "pointer",
          borderRadius: "3px",
          width: "40px",
          height: "40px",
          margin: "10px",
        }}
      >
        {" "}
        <InfoOutlined style={{ margin: "20%" }}></InfoOutlined>
      </div>
    );
  }

  function mapLoader(map) {
    setMap(map);
    overlay.setMap(map);
    map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(
      infoBtn.current
    );
    map.controls[window.google.maps.ControlPosition.BOTTOM_CENTER].push(
      mapButton.current
    );
    map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(
      logo.current
    );
    map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(
      homeBtn.current
    );

    const lineSymbol = {
      path: "M 0,-1 0,1",
      strokeOpacity: 0.6,
      strokeColor: "#FF0000",
      scale: 4,
    };
    

    for (let i = 0; i < 2; i++) {
      for (let j = 0; j < 2; j++) {
        const line = new window.google.maps.Polyline({
          strokeOpacity: 0,
          icons: [
            {
              icon: lineSymbol,
              offset: "0",
              repeat: "20px",
            },
          ],
          path: [
            { lat: nsBounds[i], lng: ewBounds[j] },
            { lat: nsBounds[i], lng: ewBounds[j + 1] },
          ],
        });
        const line2 = new window.google.maps.Polyline({
          strokeOpacity: 0,
          icons: [
            {
              icon: lineSymbol,
              offset: "0",
              repeat: "20px",
            },
          ],
          path: [
            { lat: nsBounds[i + 1], lng: ewBounds[j] },
            { lat: nsBounds[i], lng: ewBounds[j] },
          ],
        });
        line.setMap(map);
        line2.setMap(map);
      }
    }
  }
  return (
    <div className="App">
      <LoadScript googleMapsApiKey={process.env.REACT_APP_API_KEY}>
        <GoogleMap
          onClick={onClick}
          onLoad={(map) => {
            mapLoader(map);
          }}
          center={center}
          options={{
            styles: darkmode,
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            zoomControlOptions: {
              position: window.google
                ? window.google.maps.ControlPosition.RIGHT_TOP
                : null,
            },
          }}
          mapContainerStyle={{
            width: "100%",
            height: "100vh",
          }}
          zoom={14.5}
        >
          {path && (
            <Polyline
              path={path}
              options={{ strokeColor: "#FF0000", zIndex: -1 }}
            ></Polyline>
          )}
          {clicks[0] && <Marker position={clicks[0]} />}
          {clicks[1] && <Marker position={clicks[1]} />}
          {mapButtons()}
          {logoRender()}
          {homeButton()}
          {infoButton()}
        </GoogleMap>
        {loading && (
          <BounceLoader
            size={150}
            loading={true}
            style={{ position: "absolute", top: "40%", left: "46%" }}
            color={"#EB5454"}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )}

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={infoToggle}
        >
          <div
            style={{
              ...theme,
              width: "40px",
              backgroundColor:"#EB5454",
              height: "40px",
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
            }}
            onClick={() => setInfoToggle(false)}
          >
            <CloseOutlined style={{ padding: "20%", color:"white"}}></CloseOutlined>
          </div>
          <InfoComponent></InfoComponent>
        </Backdrop>
      </LoadScript>
    </div>
  );
}

export default Diss;
//   const rootElement = document.getElementById("root");
//   const root = createRoot(rootElement);
//   root.render(<Diss/>)
