const theme = {
    backgroundColor:"white",
    color:"#666666",
    borderRadius:"10px",
    fontFamily:"Nunito",
    fontSize:"1.5rem",
  }

  const mobileTheme = {
    padding:"1%",
    backgroundColor:"white",
    color:"#666666",
    borderRadius:"10px",
    fontFamily:"Nunito",
    fontSize:"4vw",
  }

const letterStyles = {
    display:"inline",
    padding: "0% 4%",
    margin: "1% 2%",
    fontSize: "3rem",
    backgroundColor: "#EB5454",
    color:"white",
    width:"fit-content",
    fontFamily: 'Nunito',
    borderRadius:"12%",
  }

  const mobileLetterStyles = {
    display:"inline",
    padding: "0% 4%",
    margin: "0.5%",
    fontSize: "2rem",
    backgroundColor: "#EB5454",
    color:"white",
    width:"fit-content",
    fontFamily: 'Nunito',
    borderRadius:"12%",
  }

const navbar = {
  width:"100%",
  textAlign:"center",
  marginBottom:"20px",
}


export {mobileTheme,theme,mobileLetterStyles,letterStyles,navbar};