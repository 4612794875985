import React, { useRef, useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useWindowWidth, useWindowSize } from "@react-hook/window-size";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import MobileHome from "./MobileHome";

function Home() {
  const ref = useRef(null);
  const width = useWindowWidth(); 


  const rightColumnItems = {
    display: "block",
    marginLeft: "auto",
    position: "fixed",
    right: "10px",
  };

  const subheadingStyles = {
    scrollSnapAlign: "start",
    display: "inline-block",
    fontSize: "2.3vw",
    border: "1px solid black",
    backgroundColor: "white",
    padding: "10px",
    fontFamily: "Montserrat",
    cursor: "pointer",
  };


  const profileImgStyles = {
    borderRadius: "20px",
    width: "35%",
    minWidth: "400px",
  };

  const headingTextStyles = {
    fontSize: "10vw",
    fontFamily: "Lora",
  };

  const miniIconStyle = {
    width: "5vw",
    minWidth: "50px",
    maxWidth: "80px",
    display: "inline-block",
  };

  const hoverAni = {};

  const skillsIcons = [
    "js_icon.png",
    "python_icon.png",
    "java_icon.png",
    "cpp_icon.png",
    "git_icon.png",
    "docker_icon.png",
  ];
  return (
    <>
      {width < 600 ? (
        <MobileHome></MobileHome>
      ) : (
        <span>
          <Parallax
            ref={ref}
            pages={3.65}
            style={{
              scrollSnapType: "y mandatory",
              width: "-webkit-fill-available",
              height: "-webkit-fill-available",
              paddingLeft: "10px",
              backgroundColor: "#F3F3F3",
            }}
          >
            <ParallaxLayer
              offset={0}
              speed={0}
              onScroll={(e) => {
                console.log(e);
              }}
              style={{ scrollSnapAlign: "start" }}
            >
              <p style={{ ...subheadingStyles, marginTop: "5px" }}>PORTFOLIO</p>
            </ParallaxLayer>
            <ParallaxLayer offset={0} speed={0}>
              <div style={{ ...rightColumnItems }}>
                <a target="_blank" href="https://github.com/chris114ab">
                  <img
                    alt=""
                    style={miniIconStyle}
                    src="icons/github_icon.png"
                  ></img>
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/chris-abraham101/"
                >
                  <img
                    alt=""
                    style={miniIconStyle}
                    src="icons/linkedin_icon.png"
                  ></img>
                </a>
              </div>
            </ParallaxLayer>
            <ParallaxLayer offset={0.09} speed={0.5}>
              <img
                alt=""
                style={{ ...profileImgStyles, ...rightColumnItems }}
                src="images/profile.jpg"
              ></img>
            </ParallaxLayer>
            <ParallaxLayer offset={0.1} speed={0}>
              <h1 style={headingTextStyles}>
                Chris <br></br> Abraham
              </h1>
            </ParallaxLayer>
            <ParallaxLayer
              style={{ scrollSnapAlign: "start" }}
              offset={0.87}
              speed={0}
              onClick={() => {
                ref.current.scrollTo(0.87);
              }}
            >
              <p style={subheadingStyles}>SOFTWARE DEVELOPER</p>
            </ParallaxLayer>

            <ParallaxLayer offset={1} speed={0.5}>
              <div style={{
                  width: "40%",
                  overflow: "hidden",
                }}>
              <img
                alt=""
                style={{
                  width: "100%",
                  minWidth: "500px",
                  borderRadius: "20px",
                }}
                src="images/glasgow.png"
              ></img>
              </div>
            </ParallaxLayer>
            <ParallaxLayer offset={1} speed={0}>
              <div style={{ ...rightColumnItems, width: "55%" }}>
                <h2 style={{ fontFamily: "Lora", fontSize: "3vw" }}>
                  Masters Student at Glasgow University
                </h2>
                <p style={{ fontFamily: "Lora", fontSize:width<1100?"1.5vw":"1.1vw" }}>
                  {" "}
                  I'm entering my 5th year of a Masters's in Software
                  Engineering at Glasgow University expecting a first. From 4
                  years at Uni I have gathered plenty of lessons from every
                  avenue of computing, all making me a better well rounded
                  software engineer.<br></br> My particular research interest
                  for my Masters Project, is graph based neural networks, and
                  how we can efficiently apply learning techniques to graph
                  strucures.
                  <br></br>
                  Alongside uni, I also work for Glasgow University Software
                  Service{" "}
                  <a
                    href="https://www.gla.ac.uk/schools/computing/staff/index.html/staffcontact/person/4edeecec8594"
                    target="_blank"
                  >
                    (GUSS)
                  </a>{" "}
                  as a Software Developer, working on exciting internal projects
                  at the university! <br></br>
                  {/* Outside work and uni I enjoy cooking new dishes, going to the gym and seeing music gigs  */}
                </p>
              </div>
            </ParallaxLayer>
            <ParallaxLayer
              style={{ scrollSnapAlign: "start" }}
              offset={1.74}
              speed={0}
              onClick={() => {
                ref.current.scrollTo(1.74);
              }}
            >
              <p style={subheadingStyles}>ABOUT ME</p>
            </ParallaxLayer>
            <ParallaxLayer offset={1.99} speed={0}>
              <div style={{ paddingLeft: "10%", width: "40%" }}>
                <h2 style={{ fontFamily: "Lora", fontSize: "3vw" }}>
                  My Skills
                </h2>
                <p style={{ fontFamily: "Lora", fontSize:width<1100?"1.5vw": "1.1vw" }}>
                  I have a diverse range of experience spanning the entire
                  development stack and I am proficient in most commonly used
                  programming languages.
                  <br></br> I have a deep understanding of essential development
                  tools like Git and Docker. I also have plenty of experience
                  implementing Agile methodologies into my daily work, being
                  adaptable and able to pivot quickly to changing requirements.
                  <br></br>I firmly believe that my skills would be an
                  invaluable addition to any team!
                </p>
              </div>
            </ParallaxLayer>

            <ParallaxLayer offset={1.99} speed={0.2}>
              <div style={{ ...rightColumnItems, width: "40%" }}>
                <Grid container spacing={3}>
                  {skillsIcons.map((skill, index) => (
                    <Grid item xs={6} style={{}}>
                      <motion.img
                        whileHover={{
                          rotateY: 180,
                          scale: 1.1,
                          transition: { duration: 0.35 },
                          zIndex: 10,
                          boxShadow:
                            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                        }}
                        key={index}
                        style={{
                          borderRadius: "10px",
                          padding: "20px",
                          backgroundColor: "white",
                          width: "45%",
                        }}
                        src={"icons/" + skill}
                      ></motion.img>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </ParallaxLayer>
            <ParallaxLayer
              style={{ scrollSnapAlign: "start" }}
              offset={2.61}
              speed={0}
              onClick={() => {
                ref.current.scrollTo(2.61);
              }}
            >
              <p style={subheadingStyles}>PROJECTS</p>
            </ParallaxLayer>

            <ParallaxLayer offset={2.8}>
              <div style={{ paddingLeft: "10%", width: "40%" }}>
                <h1 style={{ fontFamily: "Lora", fontSize: "3vw" }}>
                  Previous Projects
                </h1>
                <p style={{ fontFamily: "Lora", fontSize:width<1100?"1.5vw": "1.1vw" }}>
                  My public github has some of my older projects, from when I
                  started learning to code. However more recently, I have worked
                  on a couple larger projects, my Honours Dissertation, and of
                  course, this site!
                  <br></br>Linked here is my Dissertation product which
                  visualises the progression of popular path-finding algorithms,
                  Dijkstras and A-star. My dissertation analysed the performance
                  of simple algorithms like dijkstra's on a complex data set,
                  which in this case is the road network of Glasgow, to
                  visualise my analysis I made this web app that shows the nodes
                  explored by each algorithim in turn. (I explain the
                  visualisations in more detail in the dissertation, contact me
                  on LinkedIn for a copy){" "}
                </p>
              </div>
            </ParallaxLayer>
            <ParallaxLayer offset={2.8}>
              <Link to={"/dissertation"}>
                <img
                  // onMouseEnter={setHovering(true)}
                  // onMouseLeave={setHovering(false)}

                  alt=""
                  style={{ ...rightColumnItems, width: "45%" }}
                  src="images/project.png"
                ></img>
              </Link>
            </ParallaxLayer>
            <ParallaxLayer
              style={{ cursor: "pointer" }}
              offset={3.48}
              speed={0}
              onClick={() => {
                ref.current.scrollTo(0);
              }}
            >
              <p style={{ ...subheadingStyles, display: "inline-block" }}>
                CONTACTS
              </p>

              <div
                style={{ display: "inline", position: "absolute", top: "2%" }}
              >
                <a target="_blank" href="https://github.com/chris114ab">
                  <img
                    alt=""
                    style={miniIconStyle}
                    src="icons/github_icon.png"
                  ></img>
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/chris-abraham101/"
                >
                  <img
                    alt=""
                    style={miniIconStyle}
                    src="icons/linkedin_icon.png"
                  ></img>
                </a>
              </div>
            </ParallaxLayer>
          </Parallax>
        </span>
      )}
    </>
  );
}

export default Home;
