import React, { useRef } from "react";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { css } from "@emotion/react";
import "./style.css"


import { Parallax, ParallaxLayer } from "@react-spring/parallax";

function MobileHome() {
  const ref = useRef();
  const rightColumnItems = {
    display: "block",
    marginLeft: "auto",
    position: "fixed",
    right: "10px",
  };

  const subheadingStyles = {
    scrollSnapAlign: "start",
    marginLeft:"10px",
    fontSize: "5vw",
    border: "1px solid black",
    backgroundColor: "white",
    display: "inline-block",
    padding: "10px",
    fontFamily: "Montserrat",
  };

  const profileImgStyles = {
    width: "75vw",
  };

  const headingTextStyles = {
    display:"inline-block",
    fontSize: "20vw",
    fontFamily: "Lora",
  };

  const miniIconStyle = {
    width: "5vw",
    minWidth: "50px",
    maxWidth: "80px",
    display: "inline-block",
  };

  const skillsIcons = [
    "js_icon.png",
    "python_icon.png",
    "java_icon.png",
    "cpp_icon.png",
    "git_icon.png",
    "docker_icon.png",
  ];

  return (
    
    <span style={{ width: "100%", height: "100" }}>
      <Parallax
      id="parallax"
        ref={ref}
        style={{ width: "100%", height: "100%", backgroundColor: "#F6F6F6" ,overflow:"scroll", scrollSnapType: "y mandatory"}}
      >
        <ParallaxLayer offset={0} speed={0} style={{scrollSnapAlign:"start"}}>
          <p style={subheadingStyles}>PORTFOLIO</p>
        </ParallaxLayer>
        <ParallaxLayer offset={0} speed={0}>
          <div style={{ ...rightColumnItems }}>
            <a href="https://github.com/chris114ab">
              <img style={miniIconStyle} src="icons/github_icon.png"></img>
            </a>
            <a href="https://www.linkedin.com/in/chris-abraham101/">
              <img style={miniIconStyle} src="icons/linkedin_icon.png"></img>
            </a>
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={0.35} speed={0}>
          <img
            style={{ ...profileImgStyles, ...rightColumnItems }}
            src="images/profile.jpg"
          ></img>
        </ParallaxLayer>
        <ParallaxLayer offset={0.03} speed={0}>
          <h1 style={headingTextStyles}>
            Chris <br></br> Abraham
          </h1>
        </ParallaxLayer>
        <ParallaxLayer onClick={()=> {ref.current.scrollTo(0.9)}} offset={0.9} speed={0} style={{scrollSnapAlign:"start"}}>
          <p style={subheadingStyles}>
            SOFTWARE DEVELOPER
          </p>
        </ParallaxLayer>
        <ParallaxLayer offset={1.1} speed={0}>
          <img
            style={{ width: "140%", position:"absolute", right:"55%", borderRadius:"20px"}}
            src="images/glasgow.png"
          ></img>
        </ParallaxLayer>
        <ParallaxLayer offset={1} speed={0}>
          <div style={{ ...rightColumnItems, width: "50%",marginTop: "10%" }}>
            <h2 style={{ fontFamily: "Lora", fontSize: "6vw" }}>
              Masters Student at Glasgow University
            </h2>
            <p style={{ fontFamily: "Lora", fontSize: "3vw" }}>
              {" "}
              I'm entering my 5th year of a Masters's in Software
                  Engineering at Glasgow University expecting a first. From 4
                  years at Uni I have gathered plenty of lessons from every
                  avenue of computing, all making me a better well rounded
                  software engineer.<br></br> My particular research interest
                  for my Masters Project, is graph based neural networks, and
                  how we can efficiently apply learning techniques to graph
                  strucures.
                  <br></br>
                  Alongside uni, I also work for Glasgow University Software
                  Service{" "}
                  <a
                    href="https://www.gla.ac.uk/schools/computing/staff/index.html/staffcontact/person/4edeecec8594"
                    target="_blank"
                  >
                    (GUSS)
                  </a>{" "}
                  as a Software Developer, working on exciting internal projects
                  at the university! <br></br>
                  {/* Outside work and uni I enjoy cooking new dishes, going to the gym and seeing music gigs  */}
            </p>
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={1.8} speed={0} onClick={()=> {ref.current.scrollTo(1.8)}} style={{scrollSnapAlign:"start"}}>
        <p style={subheadingStyles}>
            ABOUT ME
          </p>
        </ParallaxLayer>
        <ParallaxLayer offset={1.9} speed={0}>
          <div style={{ paddingLeft: "7%"}}>
            <h2 style={{ fontFamily: "Lora", fontSize: "10vw" }}>My Skills</h2>
            <p style={{ fontFamily: "Lora", fontSize: "3vw" }}>
            I have a diverse range of experience spanning the entire
                  development stack and I am proficient in most commonly used
                  programming languages.
                  <br></br> I have a deep understanding of essential development
                  tools like Git and Docker. I also have plenty of experience
                  implementing Agile methodologies into my daily work, being
                  adaptable and able to pivot quickly to changing requirements.
                  <br></br>I firmly believe that my skills would be an
                  invaluable addition to any team!
            </p>
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={2.35} speed={0}>
          <div style={{marginLeft:"10%"}}>
            <Grid
              container
              spacing={1}
            >
              {skillsIcons.map((skill, index) => (
                <Grid item xs={4} style={{}}>
                  <motion.img
                    whileHover={{rotateY: 180,
                      scale: 1.1,
                      transition: { duration: .35 },
                      zIndex: 10,
                      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px'}}
                    key={index}
                    style={{
                      borderRadius: "10px",
                      padding: "10px",
                      backgroundColor: "white",
                      width: "50%",
                    }}
                    src={"icons/" + skill}
                  ></motion.img>
                </Grid>
              ))}
            </Grid>
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={2.7} speed={0}  style={{scrollSnapAlign:"start"}}>
        <p style={subheadingStyles}>
            PROJECTS
          </p>
        </ParallaxLayer>
        <ParallaxLayer offset={2.8} style={{height:"fit-content"}}>
          <div style={{ paddingLeft: "6%", width: "70%", marginBottom:"10%" }}>
            <h1 style={{ fontFamily: "Lora", fontSize: "8vw" }}>
              Previous Projects
            </h1>
            </div>
            <div style={{ paddingLeft: "6%", width: "50%" }}>
            <p style={{ fontFamily: "Lora", fontSize: "3vw" }}>
            My public github has some of my older projects, from when I
                  started learning to code. However more recently, I have worked
                  on a couple larger projects, my Honours Dissertation, and of
                  course, this site!
                  <br></br>Linked here is my Dissertation product which
                  visualises the progression of popular path-finding algorithms,
                  Dijkstras and A-star. My dissertation analysed the performance
                  of simple algorithms like dijkstra's on a complex data set,
                  which in this case is the road network of Glasgow, to
                  visualise my analysis I made this web app that shows the nodes
                  explored by each algorithim in turn. (I explain the
                  visualisations in more detail in the dissertation, contact me
                  on LinkedIn for a copy){" "}
            </p>
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={2.9} style={{height:"fit-content"}}>
            <Link to={"/dissertation"}><img
              style={{ position:"absolute",left:"60%", width: "140%" }}
              src="images/project.png"
            ></img></Link>
        </ParallaxLayer>

        <ParallaxLayer offset={3.6} onClick={()=> {ref.current.scrollTo(0)}} style={{height:"fit-content"}}>
          <p style={{...subheadingStyles, display: "inline-block"}}>CONTACTS</p>
          <div
                style={{ display: "inline", position: "absolute", top: "17%" }}
              >
                <a target="_blank" href="https://github.com/chris114ab">
                  <img
                    alt=""
                    style={miniIconStyle}
                    src="icons/github_icon.png"
                  ></img>
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/chris-abraham101/"
                >
                  <img
                    alt=""
                    style={miniIconStyle}
                    src="icons/linkedin_icon.png"
                  ></img>
                </a>
              </div>
        </ParallaxLayer>
      </Parallax>
    </span>
  );
}

export default MobileHome;
