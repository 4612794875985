import { theme } from "./diss_styles";
import * as React from "react";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useWindowWidth } from "@react-hook/window-size";
const images = [
  {
    label:
      "Click on the map anywhere, within the dotted red outline, to set a start and end point",
  },
  {
    label:
      "Select your chosen algorithm, either Dijkstra or A-Star, and let it run!",
  },
  {
    label:
      "Once finished it will plot the nodes visited, use the slider move it manually",
  },
];

function InfoComponent() {
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  return (
    <Box sx={{ maxWidth: useWindowWidth()>600 ? "80%" : "90%" , flexGrow: 1, ...theme, padding: "0% 3%" }}>
      <h2>Welcome to my Dissertation product!</h2>
      <p>
        The purpose of the app is to show the progression of popular path
        finding algorithms in a real world context. Due memory contraits, the
        map is limited to a smaller area (the red dotted lines).
      </p>

      <div style={{ width: "fit-content", margin: "auto" }}>
        <video width="70%" style={{marginLeft:"15%"}} controls autoplay loop muted>
          <source src="images/tutorial.mp4" type="video/mp4" />
        </video>
      </div>
      <Paper
        square
        elevation={0}
        sx={{
          alignItems: "center",
          height: 50,
          pl: 2,
          bgcolor: "background.default",
          display: "contents",
          textAlign: "center",
        }}
      >
        <p style={{ ...theme, margin: "auto" }}>{images[activeStep].label}</p>
      </Paper>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            style={{ ...theme, border: "1px solid black" }}
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button
            size="small"
            onClick={handleBack}
            disabled={activeStep === 0}
            style={{ ...theme, border: "1px solid black" }}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
  );
}

export default InfoComponent;
